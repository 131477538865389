import $ from "jquery";
import GLightbox from "glightbox";

$(window).on('load', function () {
	$(".glightbox, .glightbox-video").on("click", function (e) {
		e.preventDefault();
	});
	const lightbox = GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true,
	});
	const lightboxVideo = GLightbox({
		selector: '.glightbox-video',
		touchNavigation: true,
		loop: false,
		autoplayVideos: true,
		config: {
            youtube: {
                noCookie: true,
                rel: 0,
                showinfo: 0,
                iv_load_policy: 3
            },
        }
	});
});
