import $ from "jquery";
// import "bootstrap";

$(function () {
	let cast_creative = $(".cast-creative-staff.has-bio");
	
	cast_creative.on("click", function (e) {
		
		let $this = $(this);
		e.preventDefault();

		if ($this.find(".bio").length == 0) {
			return;
		}

		if ($this.hasClass("cc_popup")) {
			
			let bio_html = $(".bio_html", $this).html();

			$("#ccModal .modal-body").html(bio_html);
			let bioModal = new bootstrap.Modal(document.getElementsByClassName("bio"), {});
			bioModal.show();
		} else {
			let parentContainer = $(this).closest(".row");
			// clone Bio Content to full width viewer
			let bio = $(this).find(".bio").clone();
			if ($(this).hasClass("active")) {
				$(this).removeClass("active");

				$(".full-width-viewer").slideUp(function () {
					$(this).remove();
				});
			} else {
				cast_creative.removeClass("active");
				$(this).addClass("active");
				$(".full-width-viewer").remove();
				parentContainer
					.after('<div class="full-width-viewer text-center py-2 py-lg-3 mb-lg-6"><article><header class="py-4"></header><footer></footer></article></div>')
					.promise()
					.done(function () {
						$(".full-width-viewer article footer").html(bio);
						$(".full-width-viewer article footer bio").removeClass("d-none");
						$(".full-width-viewer article header").html('<button class="close"><svg><use xlink:href="#close"></use></svg></button>');
						$(".full-width-viewer").slideDown();
						$("html,body").animate(
							{
								scrollTop: $(".full-width-viewer").offset().top - $('header[role="main"]').innerHeight(),
							},
							500
						);
					});
			}
		}
	});

	
});
