// AKA Base Solution
// Version 1.1
//
// Authors: Vinicius Artioli, Catia Melo, Tony Possamai, Chris Muldoon, Josh Winterbottom, Jeremy Basolo,Sam Johnson, Aung Myo Win

import "../scss/styles.scss";
import "jquery";
window.$ = jQuery;
// import 'bootstrap/';
import "bootstrap/js/dist/util";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/collapse";
// import "./common/widont"; // to remove widows/orphans

import GLightbox from "glightbox";

// BLOCKS
import "./blocks/block-cast-and-creative";
import "./blocks/block-quote-slider";
import "./blocks/block-hero-quote-slider";
import "./blocks/block-gallery-global";
import "./blocks/block-gallery-scroller";

$(function () {
	$("body").removeClass("loading");

	// Fixed Nav
	var header = $('header[role="main"]');
	var headerHeight = header.outerHeight(true);

	// $(window).scroll(function () {
	// 	var currY = $(window).scrollTop();

	// 	if (currY > 0) {
	// 		$("body").css({
	// 			"padding-top": headerHeight,
	// 		});
	// 		header.addClass("position-fixed");
	// 	} else {
	// 		header.removeClass("position-fixed");
	// 		$("body").css({
	// 			"padding-top": 0,
	// 		});
	// 	}
	// });

	if ($(".aka-block-hero").length != 0) {
		var sticky_heights = headerHeight;
		if ($(".sticky-msg").length != 0) {
			sticky_heights = $(".sticky-msg").outerHeight(true) + headerHeight;
		}
		console.log(sticky_heights);
		$(".aka-block-hero .artwork").css("height", "calc(100vh - " + sticky_heights + "px)");
	}

	// lightbox
	if ($(".glightbox").length != 0) {
		var lightboxVideo = GLightbox({
			selector: ".glightbox",
			// type: 'video',
			loop: true,
			touchNavigation: false,
			autoplayVideos: true,
		});
	}
});
