import $ from "jquery";
import 'slick-carousel';


$(function () {

	var $slider = $('.gallery-scroller .photo-tab-content-scroller');
	const options = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        pauseOnHover: false,
        centerMode: true,
        centerPadding: '15%',
        mobileFirst: true,
        arrows: true,
		nextArrow: '<div class="position-static position-lg-absolute mx-1 translate-middle-none translate-middle-lg-y slick-arrow slick-next"></div>',
        prevArrow: '<div class="position-static position-lg-absolute mx-1 translate-middle-none translate-middle-lg-y slick-arrow slick-prev"></div>',
        appendArrows: $slider.closest('section').find('.slider-nav'),
        dots: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					centerPadding: '30%',
				}
			},
		]
    }

	$slider.slick(options); 

});
