import $ from "jquery";
import Splide from "@splidejs/splide";
$(function () {
	let quote_slider = document.querySelectorAll(".aka-quote-slider");
	if (quote_slider.length > 0) {
		for (let i = 0; i < quote_slider.length; i++) {
			let quote_sliderElement = quote_slider[i];
			let quote_sliderDefaultOptions = {
				type: "loop",
				arrows: "slider",
				pagination: false,
				trimSpace: false,
				perPage: 1,
				perMove: 1
			};
			let quotes = new Splide(quote_sliderElement, quote_sliderDefaultOptions);
			quotes.mount();
		}
	}

});
