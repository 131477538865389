import $ from "jquery";
import Splide from "@splidejs/splide";

$(function () {
	// Quote Slider
	let quote_slider = document.querySelectorAll(".hero-quote-slide");
	if (quote_slider.length > 0) {
		for (let i = 0; i < quote_slider.length; i++) {
			let quote_sliderElement = quote_slider[i];
			let quote_sliderDefaultOptions = {
				type: "loop",
				arrows: true,
				pagination: false,
				trimSpace: false,
				perPage: 1,
				perMove: 1,
				live: false,
			};
			let quotes = new Splide(quote_sliderElement, quote_sliderDefaultOptions);
			quotes.mount();
		}
	}

	// Function to set block height
	function setCoverImageHeight() {
		if ($(window).width() > 768) {
			let headerHeight = $("#header").outerHeight();
			let viewportHeight = $(window).height();
			let newHeight = viewportHeight - headerHeight - 20;
			$(".artwork img").css("height", newHeight + "px");
		} else {
			$(".artwork img").css("height", "auto");
		}
	}

	// Function to have theatrre messaged fixed
	const theatreBanner = $("#theatre-banner");

	function handleBanner() {
		const siteWidth = $(window).innerWidth();
		if (siteWidth < 1024) {
			theatreBanner.removeClass("sticky-msg");
			return;
		}

		const viewportTop = $(window).scrollTop();

		if (viewportTop >= 50) {
			theatreBanner.removeClass("sticky-msg");
		} else {
			theatreBanner.addClass("sticky-msg");
		}
	}

	// handleBanner();
	setCoverImageHeight();

	// Run on page resize
	$(window).resize(function () {
		// handleBanner();
		setCoverImageHeight();
	});

	// Run on scroll
	// $(window).scroll(function () {
	// 	// handleBanner();
	// });
});
